var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CbDrawer",
    {
      attrs: {
        "is-mask": "",
        title: _vm.mode === "add" ? "" : "预览",
        size: "normal",
        "wrap-class-name": "cbDrawer",
        visible: _vm.visible,
        closable: _vm.mode !== "add",
        "display-btn": true,
      },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u(
        [
          _vm.mode === "add"
            ? {
                key: "title",
                fn: function () {
                  return [
                    _c("HeaderBack", {
                      attrs: { title: "预览" },
                      on: { back: _vm.onClose },
                    }),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _c("PhonePanel", {
        attrs: { height: "100%" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("p", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.info.institutionTitle)),
                ]),
                _c("p", { staticClass: "subhead" }, [
                  _vm._v(_vm._s(_vm.info.createTime)),
                ]),
                _c("div", {
                  staticClass: "cont",
                  domProps: { innerHTML: _vm._s(_vm.info.institutionText) },
                }),
                _vm._l(_vm.info.uploadFile, function (item) {
                  return _c("div", { key: item.fileId, staticClass: "link" }, [
                    _c("img", {
                      attrs: { src: _vm.findIcon(item.fileName), alt: "" },
                    }),
                    _c("span", [_vm._v(_vm._s(item.fileName.split(".")[0]))]),
                  ])
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }